@mixin absolute-object {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
@mixin flex-col {
    display: flex;
    flex-direction: column;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
}

/*********col mxin***********/

@mixin flex-col-center {
    @include flex-col;
    justify-content: center;
    align-items: center;
}

@mixin flex-col-between-center {
    @include flex-col;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-col-between-start {
    @include flex-col;
    justify-content: space-between;
    align-items: flex-start;
}

@mixin flex-col-between-end {
    @include flex-col;
    justify-content: space-between;
    align-items: flex-end;
}

/**********row mxin*******/

@mixin flex-row-center {
    @include flex-row;
    justify-content: center;
    align-items: center;
}

@mixin flex-row-between-center {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-row-between-start {
    @include flex-row;
    justify-content: space-between;
    align-items: flex-start;
}

@mixin flex-row-between-end {
    @include flex-row;
    justify-content: space-between;
    align-items: flex-end;
}