.card-lift--hover {
    &:hover {
        transform: translateY(-20px);
        transition: all .15s ease;
        // @include transition($transition-base);
    }
}

.card-blockquote {
    padding: 2rem;
    position: relative;

    .svg-bg {
        display: block;
        width: 100%;
        height: 95px;
        position: absolute;
        top: -94px;
        left: 0;
    }
}

.card-profile-image {
    position: relative;

    img {
        max-width: 180px;
        border-radius: $border-radius;
        // @extend .shadow;
        transform: translate(-50%, -30%);
        position: absolute;
        left: 50%;
        transition: all .15s ease;

        &:hover {
            transform: translate(-50%, -33%);
        }
    }
}

.card-profile-stats {
    padding: 1rem 0;

    >div {
        text-align: center;
        margin-right: 1rem;
        padding: .875rem;

        &:last-child {
            margin-right: 0;
        }

        .heading {
            font-size: 1.1rem;
            font-weight: bold;
            display: block;
        }

        .description {
            font-size: .875rem;
            color: #adb5bd;
        }
    }
}

.card-profile-actions {
    padding: .875rem;
}

.card-stats {
    border: none;

    .card-body {
        padding: 1rem 1.5rem;
    }

    .card-status-bullet {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }
}

.card-translucent {
    background-color: rgba(18, 91, 152, 0.08);
}

.icon-shape {
    padding: 15px;
    width: 45px;
    height: 45px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $color-2 !important;
    color: #000 !important;
    box-shadow: none !important;
}

.card-h {
    height: 520px;
}

.card-shadow {
    box-shadow: $card-shadow;
}

.card-header {
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
    overflow: hidden;
    position: relative;
}

.card-header-form {
    &::before {
        content: ' ';
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $color-2;
        // margin-bottom: -10px;
    }
}

.cards-buts {
    color: #000 !important;
    background-color: $color-2 !important;
    border-color: $color-2 !important;
    font-size: 12px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    position: absolute;
    left: 10px;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.05) translateY(-50%);
    }
}

.form-buts {
    color: #000 !important;
    background-color: $color-2 !important;
    border-color: $color-2 !important;
    font-size: 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    position: absolute;
    left: 10px;
    padding: 5px 15px;
    transform: translateY(-100%,);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.05) translateY(-100%);
    }
}
.fs-card {
    font-size: 20px;
}

.card-item {
    @include flex-col;
    background-color: #fff;
    box-shadow: $shadow;
    border-radius: 15px;
    height: 320px;
    overflow: hidden;

    .card-img {
        width: 100%;
        height: 250px;
        object-position: center;
        object-fit: cover;
    }

    .dropdown-toggle {
        border: none;

        &::after {
            display: none;
        }
    }
}