/*-----------------------------
fonts
-------------------------------*/
@font-face {
    font-family: 'RB-bold';
    src: url('../fonts/RB-Bold.woff2') format('woff2');
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'RB-light';
    src: url('../fonts/RB-Light.woff2') format('woff2');
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Material.woff2') format('woff2');
}
