.offcanvas-edit {
    width: 30% !important;

    @include size('xxl') {
        width: 40% !important;
    }

    @include size('xl') {
        width: 45% !important;
    }

    @include size('lg') {
        width: 50% !important;
    }

    @include size('md') {
        width: 75% !important;
    }

    @include size('sm') {
        width: 100% !important;
    }
}