.ant-pagination {
    display: flex !important;
}

.ant-pagination-item {
    font-weight: 600;
    background-color: #fff !important;
    border-color: $color-2 !important;
    width: 45px !important;
    height: 45px !important;
    border-radius: 100px !important;
    color: $color-2;
    font-size: 16px;
    vertical-align: middle !important;

    &:hover {
        @include second-button;
    }

    a {
        margin: 5px;

        &:hover {
            color: #fff !important;
        }
    }
}

.ant-pagination-item-active {
    @include second-button;
    border-color: #fff !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 100px !important;
    font-size: 18px;
    vertical-align: middle !important;

    &:hover {
        @include second-button;
    }

    a {
        margin: 8px;

        &:hover {
            color: #fff !important;
        }
    }
}

.ant-pagination-item-link {
    background-color:#fff !important;
    border-color: $color-2 !important;
    width: 45px !important;
    height: 45px !important;
    border-radius: 100px !important;
    color: #fff !important;
    font-size: 18px;
    margin: 0 !important;

    svg {
        fill: $color-2;
        vertical-align: 0;
    }
}

.more-pagination {
    @include flex-col-center;
    position: relative;
    margin-top: -95px;
    background-image: linear-gradient(to top, #fff, #fff, transparent);
    z-index: 2;
    height: 150px;

    button {
        @include second-button;
    }
}