.settings {
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    min-height: 100%;

    &-title {
        font-size: 22px;
        font-family: 'RB-bold';
        padding: 20px 0;
    }

    &-info {
        font-size: 16px;
        color: #838282;
        padding-left: 20px;
    }
}