.profile-header {
    @include flex-col-between-start;
    background-color: white;
    width: 100%;
    border-radius: 15px;
    box-shadow: $shadow;
    padding: 10px 15px;
    margin-bottom: 15px;
    position: relative;

    .header-cover {
        width: 100%;
        height: 450px;
        object-fit: cover;
        object-position: center -100px;
        border-radius: 15px;
    }

    .header-image {
        position: relative;
        top: -75px;

        .header-avatar {
            width: 150px;
            height: 150px;
            border-radius: 10px;
            object-fit: cover;
            box-shadow: $shadow;
        }
    }

    .media {
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        left: 25px;
        padding: 0px 5px;
        border-radius: 5px;

        a {
            padding: 5px;
        }
    }
}

// .icon-facebook {
//     background-image: url('../../img/icons/facebook.png');
//     background-repeat: no-repeat;
//     background-size: contain;
//     width: 20px;
//     height: 20px;
//     background-position: center;
// }

// .icon-youtube {
//     background-image: url('../../img/icons/youtube.png');
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//     width: 20px;
//     height: 20px;
// }

// .icon-twitter {
//     background-image: url('../../img/icons/twitter.png');
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//     width: 20px;
//     height: 20px;
// }

// .icon-instagram {
//     background-image: url('../../img/icons/instagram.png');
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//     width: 20px;
//     height: 20px;
// }

// .icon-email {
//     background-image: url('../../img/icons/gmail.png');
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//     width: 20px;
//     height: 20px;
// }

// .icon-internet {
//     background-image: url('../../img/icons/att.png');
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//     width: 20px;
//     height: 20px;
// }