.admin-offcanvas {
    width: 250px;
}

.offcanvas-backdrop.show {
    // opacity: 0 !important;
}

.admin-sidebar {
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    overflow-y: auto;
    transition: all 0.2s linear;

    &::-webkit-scrollbar {
        display: none;
    }

    .sidebar-content {
        @include flex-col;

        .logo-sidebar {
            @include flex-col-center;
            margin-top: 20px;
            margin-top: 50px;

            img {
                width: 150px;
            }
        }

        .links-sidebar {
            @include flex-col;
            margin-top: 50px;
        }
    }
}

.sidebar-link {
    text-decoration: none;

    .content-link {
        padding: 0.65rem 1rem;
        @include flex-row;
        align-items: center;
        color: rgba(0, 0, 0, 0.5);
        border-right: 3px solid transparent;
        position: relative;

        &:hover {
            color: rgba(0, 0, 0);
            border-right: 3px solid #1171ef;
        }

        .icon-link {
            min-width: 1.75rem;
            font-size: 1rem;
            line-height: 1.5rem;
        }

        .name-link {
            font-size: 14px;
            margin: 0;
        }

        .chevron-link {
            position: absolute;
            top: 0.95rem;
            font-size: 0.7rem;
            left: 10px;
            transition: all 0.2s ease-in-out;
        }

        .transition-chevron {
            transform: rotate(90deg);
        }
    }
}

.p-r {
    padding-right: 1.7rem !important;
}

.collapse-color {
    background-color: #eeeeee6e;
}