//variables
@import './variables.scss';
@import './fonts.scss';
@import './global.scss';
//mixin index
@import './mixin/index.scss';
//custom index
@import './custom/index.scss';
//chart index
@import './chart/index.scss';
//admin index
@import './admin/index.scss';
//views index
@import './views/index.scss';

body {
    margin: 0;
    font-family: 'RB-light';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    direction: rtl !important;
    background-color: #f7fafc;
}

code {
    font-family: 'RB-light';
}