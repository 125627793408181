$sizes: 10,
11,
12,
13,
14,
15,
16,
17,
18,
19,
20,
21,
22;

@each $size in $sizes {

    .fs-#{$size} {
        font-size: #{$size}px !important;
    }
}

.f-bold {
    font-family: 'RB-bold';
}

a {
    text-decoration: none;
    color: #000;
}

label {
    padding-bottom: 5px;
}

.btn-close,
.ant-drawer-close {
    position: absolute;
    left: 5px;
    top: 15px;
}

.modal-header {
    border-right: 5px solid $color-2;
}

.ant-picker-dropdown,
.ant-select-dropdown {
    z-index: 3000 !important;
}

form {
    position: relative;
}

.text-h {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: wrap !important;
}

.img-cover {
    object-fit: cover !important;
}

.live-palyer video {
    // display: flex;
    // flex: 1;
    width: 100% !important;
    height: 450px !important;
    // width: inherit;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}

.icon-input {
    position: relative;
    left: -5px;
    font-size: 22px;
    color: rgb(221, 220, 220);
    padding: 0 0 0 5px;
}

.icon-select {
    display: flex;
    font-size: 22px;
    color: rgb(221, 220, 220);
    position: relative;
    justify-content: center;
    align-content: center;
}

.optional {
    position: relative;
}

.optional:after {
    content: '*';
    font-size: 12px;
    padding: 2px;
    color: #f00;
    position: absolute;
    top: -7px;
    left: 0px;

}

.pointer:hover {
    cursor: pointer;
    color: blue;
}