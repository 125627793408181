//
// Chart
//

.chart {
    position: relative;
    height: 350px;
}


// Size variations

.chart-sm {
    height: 230px;
}


// Legend

.chart-legend {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
    font-size: 1rem * .875;
    text-align: center;
    color: #8898aa;
}

.chart-legend-item {
    display: inline-flex;
    align-items: center;

    +.chart-legend-item {
        margin-left: 1rem;
    }
}

.chart-legend-indicator {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.375rem;
    border-radius: 50%;
}


// Tooltip

#chart-tooltip {
    z-index: 0;

    .arrow {
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateX(-.5rem);
    }
}


// Chart info overlay

.chart-info-overlay {
    position: absolute;
    top: 0;
    left: 5%;
    max-width: 350px;
    padding: 20px;
    z-index: 1;
}

.bg-gradient-default {
    background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important;
}

.chart-nav {
    .nav-link {
        padding: 0.45rem 1rem;
        color: #5e72e4;
        font-weight: 500;
        vertical-align: middle;
        font-size: 0.875rem;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        background-color: #fff;
        transition: all 0.15s ease;
    }

    .nav-link.active {
        color: #fff;
        background-color: #5e72e4;
    }
}