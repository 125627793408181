.but-all {
    font-size: 15px;
    color: #000 !important;
    background-color: $color-2 ;
    vertical-align: middle !important;

    &:hover {
        background-color: $color-3;
    }
}

.but-bottom {
    position: absolute;
    bottom: 10px;
}

.home-link-1 {
    font-size: 15px;
    color: #000 !important;
    background-color: $color-2;
    padding: 5px 10px;
    border-radius: 5px 0 0 5px;

    &:hover {
        background-color: $color-3;
    }
}

.home-link-2 {
    font-size: 15px;
    color: #000 !important;
    background-color: $color-3;
    padding: 5px 7px;
    border-radius: 0 5px 5px 0;

    &:hover {
        background-color: $color-2;
    }
}

.nav.nav-tabs {
    border: none;
    min-height: 100%;
    border-bottom: 1px solid #eee;
    padding: 0px;

    .tab-content {
        min-height: 500px !important;
    }

    .nav-item {
        .nav-link {
            background-color: $color-2 !important;
            color: white !important;
            margin: 10px 5px !important;
            padding: 5px 25px;
            border-radius: 10px !important;

            i {
                font-size: 16px !important;
            }
        }
    }
}
.section-option{
    .dropdown-menu{
    direction: rtl !important;
    text-align: right !important; 
    }
   
    .dropdown-toggle{
        background-color: $color-2;
        border-radius: 5px !important;
        border: none !important;
        &:active{
            background-color: $color-2;   
        }
        &::after{
        display: none !important;
    } 
    }
   
}