.uploader {
    padding-top: 5px;
    position: relative;
}

.uploader-user {
    padding-top: 5px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.avatar-upload-item {
    display: flex;
    position: relative;

    .avatar-edit-item {
        position: absolute;
        z-index: 1;

        input {
            display: none;
        }

        input+label {
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-bottom: 0;
            border-radius: 100%;
            background: #ffffff;
            border: 1px solid #eee;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
            cursor: pointer;
            font-weight: normal;
            transition: all 0.2s ease-in-out;

            &:hover {
                background: #f1f1f1;
                border-color: #d6d6d6;
            }

            &:after {
                content: "\f067";
                font-family: "FontAwesome";
                color: #757575;
                position: absolute;
                top: 10px;
                left: 0;
                right: 0;
                text-align: center;
                margin: auto;
            }
        }
    }

    .avatar-preview-item {
        min-height: 192px;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-image: url('../../img/upload.png');
        background-color: rgb(241, 241, 241);
        background-repeat: no-repeat;
        background-size: 75px;
        background-position: center;
        border: 6px solid #fff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        position: relative;
    }

    .video-preview-item {
        height: 192px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        // background-image: url('../../img/icons/upload/video.png');
        background-repeat: no-repeat;
        background-size: 75px;
        background-position: center;
        border: 6px solid #fff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .audio-preview-item {
        height: 192px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        // background-image: url('../../img/icons/upload/mp3.png');
        background-repeat: no-repeat;
        background-size: 75px;
        background-position: center;
        border: 6px solid #fff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }
}

.avatar-preview-item {
    &:focus {
        background: none !important;
    }
}

.img-upload-item {
    height: 175px !important;
    border-radius: 10px !important;
    position: relative;
    bottom: 0;
}

.img-upload-svg {
    background-repeat: no-repeat !important;
    background-size: 100px !important;
    background-position: center;
}

.file-information {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    background-color: rgb(255, 255, 255);
    height: 180px;
    padding: 0 15px;
    border-radius: 10px;
    position: relative;
    width: 100%;
}

/*-------------------------------
upload video
--------------------------------*/
.file-information-video {
    @include flex-col-center;
    background-color: rgb(255, 255, 255);
    // background-image: url('../../img/icons/upload/video.png');
    background-position: center;
    background-size: 50px;
    background-repeat: no-repeat;
    height: 125px;
    border-radius: 10px;
    position: relative;
    width: 100%;
}

.video-upload {
    width: 100% !important;
    background-color: #ffffff;
}

/*-------------------------------
upload audio
--------------------------------*/
.file-information-audio {
    @include flex-col-center;
    background-color: rgb(255, 255, 255);
    // background-image: url('../../img/icons/upload/music.png');
    background-position: center;
    background-size: 50px;
    background-repeat: no-repeat;
    height: 125px;
    border-radius: 10px;
    position: relative;
    width: 100%;
}

.audio-upload {
    direction: ltr !important;
    width: 100% !important;
    background-color: #ffffff;
}

/*-------------------------------
file audio
--------------------------------*/
.file-information-file {
    @include flex-col-center;
    background-color: rgb(255, 255, 255);
    // background-image: url('../../img/icons/upload/file.png');
    background-position: center;
    background-size: 50px;
    background-repeat: no-repeat;
    height: 125px;
    border-radius: 10px;
    position: relative;
    width: 100%;
}

.file-information {
    width: 100% !important;
    background-color: #ffffff;

    p {
        color: #000;
    }
}

/*-------------------------------
upload image
--------------------------------*/

.upload-box {
    display: block;
    margin: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    overflow: hidden;
}

.upload-box-user {
    display: block;
    margin: 10px;
    background-color: white;
    border-radius: 100%;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    overflow: hidden;
}

.upload-options {
    position: relative;
    height: 40px;
    background-color: $color-2;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    transition: background-color ease-in-out 150ms;

    &:hover {
        background-color: lighten($color-2, 10%);
    }

    & input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    & label {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        font-weight: 400;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        overflow: hidden;

        &::after {
            content: 'add';
            font-family: 'Material Icons';
            @include absolute-object;
            font-size: 25px;
            color: #000;
            margin: 0;
            padding: 0;
            z-index: 0;
        }

        & span {
            display: inline-block;
            width: 50%;
            height: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            vertical-align: middle;
            text-align: center;

            &:hover i.material-icons {
                color: lightgray;
            }
        }
    }
}


.js--image-preview {
    height: 175px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-image: url('../../images/dashboard_img/upload.png');
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 75px;
}

.drop {
    display: block;
    position: absolute;
    background: transparentize($color-2, .8);
    border-radius: 100%;
    transform: scale(0);
}

.animate {
    animation: ripple 0.4s linear;
}

@keyframes ripple {
    100% {
        opacity: 0;
        transform: scale(2.5);
    }
}