.table {
    thead th {
        padding-top: .75rem;
        padding-bottom: .75rem;
        font-size: .65rem;
        text-transform: uppercase;
        text-overflow: ellipsis;
        letter-spacing: 1px;
        border-bottom: 1px solid #e9ecef;
    }

    th {
        font-weight: 600;
    }

    td {
        .progress {
            height: 3px;
            width: 120px;
            margin: 0;
        }
    }

    td,
    th {
        font-size: .8125rem;
        white-space: nowrap;
        text-overflow: hidden;

    }

    &.align-items-center {

        td,
        th {
            vertical-align: middle;
        }
    }



    .thead-light {
        th {
            background-color: #f6f9fc;
            color: #8898aa;
        }
    }
}



.table-hover {
    tr {
        // @include transition($transition-base);
    }
}


// Flush tables

.table-flush {
    position: relative;
    overflow: hidden;

    td,
    th {
        border-left: 0;
        border-right: 0;

    }



    tbody {
        tr {
            &:first-child {

                td,
                th {
                    border-top: 0;
                }
            }



            &:last-child {

                td,
                th {
                    border-bottom: 0;
                }
            }
        }
    }
}

.card {
    position: relative;
    overflow: hidden;

    .table {
        margin-bottom: 0;
        td,
        th {
            padding-left: 1.5rem;
            padding-right: 1.5rem;

        }

    
    }
}
