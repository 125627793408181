.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
}

.back-loading {
    align-self: center;
    background-image: url('../../images/dashboard_img/logo.png');
    background-size: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 300px;
}


.load-wrapp {
    p {
        padding: 0 0 20px;
    }

    &:last-child {
        margin-right: 0;
    }
}

.spinner {
    position: relative;
    width: 45px;
    height: 45px;
    margin: 15px auto;
}

.bubble-1 {
    position: absolute;
    top: 20px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background-color: var(--mainColor);
}

.bubble-2 {
    position: absolute;
    top: 20px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background-color: var(--mainColor);
    top: auto;
    bottom: 15px;
}

.load-9 {
    .spinner {
        animation: loadingI 2s linear infinite;
    }

    .bubble-1 {
        animation: bounce 2s ease-in-out infinite;
    }

    .bubble-2 {
        animation: bounce 2s ease-in-out infinite;
        animation-delay: -1s;
    }
}

.loading-title {
    color: #c5c2c2;
    font-size: 12px;
    font-family: 'jamalBold' !important;
}