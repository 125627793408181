@import './login';
@import './navbar.scss';
@import './sidebar.scss';



.page-container {
    padding: 10px;
    margin-top: 50px;
    padding-bottom: 30px;
    z-index: 1;
    transition: all 0.2s linear;

    &::after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 250px;
        z-index: -1;
        //background: #25A2DC;
        background-image: linear-gradient(to right, #25A2DC, #5055A5);
    }
}

.add-mr {
    margin-right: 250px;

    @include size('xl') {
        margin-right: 0px;
    }
}