.admin-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: all 0.2s linear;

    .nav-text {
        color: #fff !important;
    }

}

.fixed-top {

    background-color: #fff;

    .nav-text {
        color: #000 !important;
    }
}

.search-navbar {
    margin: 3px;
    height: 40px;
    border-radius: 20px;
    background-color: transparent !important;
    border: 2px solid rgba(255, 255, 255, 0.6) !important;

    .ant-input-prefix {
        color: rgba(255, 255, 255, 0.6) !important;
    }

    .ant-input {
        background-color: transparent !important;

        &::placeholder {
            color: rgba(255, 255, 255, 0.6) !important;
        }
    }
}