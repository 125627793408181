.container-fluid-form {
    background-color: white;
    overflow: hidden;

}

.login-color-container {
    background-image: url('../../images/dashboard_img/login/bg_aq.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100px 0 0 0;
    min-height: 100vh;
    position: relative;
    z-index: 1;
    clip-path: polygon(0% 0%, 100% 0, 97% 61%, 100% 100%, 0% 100%);
}

.login-color-container2 {
    background-image: url('../../images/dashboard_img/login/bg_aq.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100px 0 0 0;
    min-height: 100vh;
    position: relative;
    z-index: 1;
    clip-path: polygon(0% 0%, 100% 0, 97% 61%, 100% 100%, 0% 100%);
}

.login-color-container__bg {
    position: absolute;
    top: 0;
    left: 0;
    top: 0;
    right: 0;
    background-image: linear-gradient(to left, #23b5abdc, #4679e68c);
    z-index: 3;
    width: 100%;
    height: 100%;
}


.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.login-form-icon {
    background-color: $main-color;
    color: white;
    border-radius: 0 !important;
}

.title-login-form {
    font-family: 'RB-bold' !important;
    font-size: 25px;
    color: $main-color;
}

.des-login-form {
    font-size: 14px;
    color: #aaa;
}

.login-logo-form {
    width: 150px;
    margin-bottom: 100px;
}

.footer-login-form {
    position: absolute;
    bottom: 0;
    left: 0 !important;
    text-align: center;
    font-family: 'RB-bold' !important;
    font-size: 18px;
    color: #fff;
    z-index: 10;
}

.color-form-login {
    background-color: #F9F9F9;
    padding: 25px;
    border-radius: 25px;
}