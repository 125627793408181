$list: 10, 15, 20, 25, 30, 35, 40, 45, 55, 60, 65, 70, 75, 80, 85, 90, 95, 99;

@each $size in $list {

    .w-#{$size} {
        width: #{$size}+'%' !important;
    }

    .h-#{$size} {
        height: #{$size}+'%' !important;
    }

    .borders-#{$size} {
        border-radius:#{$size}px !important;
    }
}