.page-header {
    @include flex-row;
    align-items: center;

    &-link {
        @include flex-row;
        align-items: center;
        vertical-align: middle;
        color: #fff !important;
        font-size: 16px;

        i {
            font-size: 14px;
            padding: 2px;
            vertical-align: middle;
        }

        &::after {
            content: "/";
            padding: 5px;
            font-size: 15px;
            color: #eee;
            direction: rtl !important;
            
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}