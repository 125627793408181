.man-content {
    background-color: white;
    border-radius: 15px;
    box-shadow: $shadow;
    position: relative;
}

.man-link {
    @include flex-col;
    height: 100%;
    padding: 15px;

    a {
        color: #646464;
        padding: 10px 15px;
        text-align: center;
        margin: 15px 0px;
        border-radius: 25px;
        transition: all 0.2s ease-in-out;



        &:hover {
            color: #000;
            background-color: #e9f0ff;
            box-shadow: $shadow;
            font-family: 'RB-bold'
        }
    }

    .delete-link {
        color: #ff0000;

        &:hover {
            color: #fff;
            background-color: #fa3939;
        }
    }
}

.man-card-item {
    @include flex-col-between-start;
    background-color: white;
    width: 100%;
    border-radius: 15px;
    box-shadow: $shadow;
    padding: 10px 15px;
    margin-bottom: 15px;
    position: relative;


    .edit-but {
        position: absolute;
        left: 10px;
        top: 5px;
        background-color: white;
        border-radius: 25px;
        border: 1px solid #eee;
        color: #646464
    }

    .delete-but {
        position: absolute;
        left: 100px;
        top: 5px;
        background-color: #fa3939;
        border-radius: 25px;
        border: 1px solid #eee;
        color: #fff
    }

    .man-info-name {
        @include flex-col;
        justify-content: center;
        margin-right: 15px;
    }
}


